import {useIsMobile} from "../utils";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import React, {useEffect} from "react";

const Story = ({ direction = 'right', header = '', content, imageUrl, imageStyle = {} }) => {
    const { isMobile } = useIsMobile();
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 2,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            y: 70,
        },
    };
    useEffect(() => {
        if (inView) {
          controls.start("visible");
        }
    }, [controls, inView]);
    const Picture = () => <img src={imageUrl} style={{
        ...(
            isMobile ? {
                width: '100%',
                ...(imageStyle || {})
            } : {
                maxWidth: '48%',
                maxHeight: 700,
                ...(imageStyle || {})
            }
        ),
    }} />;
    const Description = () =>
        <div style={{ display: 'flex', flexDirection: 'column', ...(isMobile ? { width: '100%', alignItems: 'start'} : { maxWidth: '48%', flexGrow: 1, alignItems: 'start'})}}>
          { header && <div className="title2" style={{ lineHeight: '36px', textAlign: 'justify' }}>
                {header}
            </div>}
            <div style={{ height: 24, fontWeight: 'bold', textAlign: 'justify'}}/>
            <div className="body1">
                {content}
            </div>
        </div>
    const motionProps = {
        animate: controls,
        initial: 'hidden',
        variants,
        ref,
    }

    if (isMobile) {
        return (
            <motion.div
                style={{ display: 'flex', padding: '32px', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <Picture />
                <div style={{ height: 24}}></div>
                <Description />
            </motion.div>
        );
    }
    return (
        <motion.div
            style={{ display: 'flex', padding: '32px 64px', width: '100%', justifyContent: 'center', alignItems: 'center', gap: 64 }}>
            {
                direction === 'right' ? [
                    <Picture key="story-pic" />,
                    <Description key="story-content"/>
                ] : [
                    <Description key="story-content"/>,
                    <Picture key="story-pic"/>,
                ]
            }
        </motion.div>
    );
}

export default  Story;
